.register-page {
  display: flex;
  width: 100%;
  height: 95vh;
  align-items: center;
  justify-content: center;
  background-color: var(--fourth-accent-color);
}

.sign-up-title {
  margin: 0.5rem 0 1.5rem 0;
}

.nameLabel {
  text-shadow: 0rem 0.1rem 0.1rem var(--dark-shadow);
  margin: 0.32rem 0.625rem 0.625rem 0.32rem;
}

.nameHolder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.register {
  margin: 5rem auto 0 auto;
  width: 30rem;
  border-radius: 0.375rem;
}

.inputStyle {
  height: 1.75rem;
  padding: 0 0 0 0.625rem;
  margin: 0.32rem 0 0.625rem 0;
  border: 0.07rem solid var(--dark-shadow);
  border-radius: 0.375rem;
  flex: 1 0 auto;
  width: calc(100% - 0.625rem);
}

.inputStyle-error {
  border-color: var(--main-accent-color);
}
