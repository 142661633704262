.card-image {
  padding: 0;
}

.infoSection {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 6rem;
  justify-content: space-between;
}

.card-container,
.green-card-container,
.personalHealthCardContainer {
  padding: 0rem;
  margin: auto;
  max-width: 85rem;
}

.card-container h2 {
  text-align: center;
}

.card-left,
.card-right {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  padding: 3rem;
}

.personalHealthCard {
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}

.personalHealthHead {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  background-color: var(--white-text-color);
  padding: 0.5rem;
  border-radius: 0.375rem;
  border: 0.01rem solid var(--medium-gray-color);
}

.card-body,
.lgreen-card-body {
  padding: 2rem;
  margin: 0rem;
  max-width: 80%;
}

figure {
  margin: 0rem 0rem 2rem 0rem;
  height: fit-content;
  padding: 6rem;
}

figure img {
  width: 24rem;
  flex-shrink: 0;
  border-radius: 0.375rem;
  box-shadow: 0 0.125rem 0.32rem 0 var(--dark-shadow);
}

.card-icon {
  width: 6rem;
}

figure .icon {
  box-shadow: none;
}

.personalHealthCardContainer {
  background-color: var(--gray-highlight-color);
  border-radius: 0.375rem;
  border: 0.01rem solid var(--gray-color);
  margin: 1rem auto;
  width: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.personalHealthCardContainer p {
  font-size: 1.1rem;
}
.personalHealthCardContainer h3 {
  font-size: 1.25rem;
}
.green-card-container {
  background-color: var(--fourth-accent-color);
  border-radius: 0.375rem;
  box-shadow: 0 0.125rem 0.32rem 0 var(--dark-shadow);
  margin-top: -3rem;
  z-index: 5;
  position: relative;
  padding: 0rem;
}

.green-card-container h2,
.green-card-container p {
  color: var(--medium-highlight-color);
}

.lgreen-card-body h3 {
  text-align: left;
  color: var(--main-text-color);
}

.lgreen-card-body p {
  text-align: left;
  padding: 0rem 0rem;
  margin: 0rem;
  max-width: 100%;
  color: var(--main-text-color);
}

.green-card-container .card-body {
  padding: 1rem;
}

.green-card-container figure {
  padding: 4rem 1rem 0rem 3rem;
}

.green-card-container figure img {
  height: 100%;
  width: 22rem;
  box-shadow: none;
}

@media (max-width: 1280px) {
  .card-left {
    flex-direction: column-reverse;
  }

  .card-right {
    flex-direction: column-reverse;
  }

  .about .card-image {
    display: block;
  }

  .card-image {
    margin: 0rem;
    padding: 0rem;
    display: none;
  }

  .card-container {
    padding: 8rem 16rem;
    max-width: 100%;
    margin: auto;
  }

  .card-container {
    padding: 1rem;
    max-width: 100%;
  }

  .card-body,
  .lgreen-card-body {
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  .card-icon {
    margin: 0rem auto;
  }

  .boldText {
    text-align: center;
  }

  .green-card-container {
    height: 100%;
  }

  .green-card-container h2 {
    text-align: center;
    padding: 1rem;
    margin: auto auto 1rem auto;
  }

  .green-card-container p {
    margin: auto auto 1rem auto;
    padding: 1rem;
  }
}

@media (max-width: 960px) {
  .card-left {
    flex-direction: column-reverse;
  }

  .card-right {
    flex-direction: column-reverse;
  }

  .about .card-image {
    display: block;
  }

  .card-image {
    margin: 0rem;
    padding: 0rem;
    display: none;
  }

  .card-container {
    padding: 8rem 16rem;
    max-width: 100%;
    margin: auto;
  }

  .card-container {
    padding: 1rem;
    max-width: 100%;
  }

  .card-body,
  .lgreen-card-body {
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  .card-icon {
    margin: 0rem auto;
  }

  .boldText {
    text-align: center;
  }

  .green-card-container {
    height: 100%;
  }

  .green-card-container h2 {
    text-align: center;
    padding: 1rem;
    margin: auto auto 1rem auto;
  }

  .green-card-container p {
    margin: auto auto 1rem auto;
    padding: 1rem;
  }
}

@media (max-width: 960px) {
  .carousel {
    margin-bottom: 2rem;
  }

  .green-card-container {
    max-width: 90%;
    margin-top: -8rem;
    height: 100%;
  }

  .green-card-container .card-body {
    padding: 1rem;
  }

  .personalHealthCardContainer {
    margin: 0rem;
    box-shadow: none;
    width: 100%;
  }

  .personalHealthCardContainer .card-right,
  .personalHealthCardContainer .card-left {
    margin: auto;
    height: auto;
    width: 90%;
  }

  .lgreen-card-body {
    padding: 0.5rem;
  }

  .lgreen-card-body h3,
  .lgreen-card-body p {
    margin: 0rem 0rem;
    padding: 0.5rem;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .card-left,
  .card-right {
    margin: 0rem;
  }

  .card-container {
    padding: 2rem 4rem;
    max-width: 100%;
    margin: auto;
  }

  .card-container {
    padding: 0.1rem;
    max-width: 100%;
  }

  .card-body {
    max-width: 100%;
    padding: 1.5rem 1rem;
    text-align: center;
  }

  .card-body .horizontal-image-text {
    margin-top: 0rem;
  }

  .green-card-container h2 {
    padding: 0.5rem 0rem;
  }

  .green-card-container .card-body {
    padding: 0.5rem;
  }
}
