html {
  --main-text-color: rgb(17, 29, 66);
  --second-accent-color: rgba(17, 24, 39, 1);
  --dark-shadow: rgb(12, 16, 39);
  --second-accent-color-opacity: rgba(17, 24, 39, 0.87);
  --third-accent-color: rgb(23, 128, 119);
  --third-accent-color-highlight: rgb(23, 128, 119, 0.15);
  --fourth-accent-color: rgb(79, 116, 140);
  --fourth-accent-color-opacity: rgba(79, 116, 140, 0.5);
  --danger-color: rgb(240, 0, 0);
  --danger-opacity-color: rgb(240, 0, 0, 0.1);
  --success-color: rgb(0, 140, 0);
  --success-opacity-color: rgb(0, 140, 0, 0.1);

  /* Black */
  --black-text-color: rgb(0, 0, 0);
  --opacity-black-color: rgba(0, 0, 0, 0.1);
  --more-opacity-black-color: rgb(0, 0, 0, 0.5);
  --max-opacity-black-color: rgb(0, 0, 0, 0.7);
  --rgba-black-color: rgba(255, 255, 255, 0);
  --rgba-opacity-color: rgba(250, 250, 250, 0.1);
  --rgba-max-opacity-color: rgba(255, 255, 255, 0.03);

  /* White */
  --medium-highlight-color: whitesmoke;
  --secondary-accent-color: aliceblue;
  --white-accent-color: rgb(220, 220, 220);
  --white-text-color: rgb(255, 255, 255);
  --white-opacity-color: rgba(255, 255, 255, 0.5);
  --max-white-opacity-color: rgba(255, 255, 255, 0.8);

  /* Gray */
  --dark-gray-color: rgba(34, 41, 55, 0.7);
  --gray-highlight-color: rgba(79, 116, 140, 0.2);
  --medium-gray-color: rgba(128, 128, 128, 0.466);
  --light-gray-color: rgb(133, 138, 147);
  --dark-gray-text-color: rgb(122, 122, 122);
  --medium-gray-text-color: rgb(144, 150, 160);
  --gray-text-color: rgb(190, 190, 190);
  --gray-color: rgb(248, 248, 248);
  --light-gray-opacity-color: rgba(245, 245, 245, 0.8);
}

a:link {
  text-decoration: none;
}

footer {
  border-top: 0.01rem solid var(--white-accent-color);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p {
  font-size: 1.5rem;
}

a {
  /* font-size: 1.9rem; */
  color: var(--third-accent-color);
}

.boldText {
  font-weight: 500;
  color: var(--main-text-color);
}

hr {
  margin: 0rem;
}

h1 {
  font-size: 64px;
  font-weight: bold;
  color: var(--main-text-color);
  margin-top: 7rem;
  text-align: center;
}

h2 {
  font-weight: bold;
  font-size: 48px;
  color: var(--main-text-color);
}

h3 {
  font-weight: bold;
  font-size: 32px;
  color: var(--main-text-color);
}

h4 {
  font-weight: bold;
  font-size: 30px;
  color: var(--main-text-color);
}

h5 {
  font-weight: bold;
  font-size: 28px;
  color: var(--main-text-color);
}

h6 {
  font-weight: bold;
  font-size: 26px;
  color: var(--main-text-color);
}

th {
  color: var(--white-text-color);
}

li {
  font-size: 24px;
}

@media (max-width: 1280px) {
  a,
  p {
    font-size: 24px;
  }

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 42px;
  }

  h3 {
    font-size: 36px;
  }

  h4 {
    font-size: 32px;
  }

  h5 {
    font-size: 26px;
  }

  h6 {
    font-size: 24px;
  }
}

@media (max-width: 600px) {
  a,
  p,
  li {
    font-size: 20px;
  }

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 28px;
  }

  h4 {
    font-size: 26px;
  }

  h5 {
    font-size: 24px;
  }

  h6 {
    font-size: 22px;
  }
}
