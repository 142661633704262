.headerLinkActive {
  color: var(--main-text-color);
  border-bottom: 0.1rem solid var(--third-accent-color);
}

.headerLinkInactive {
  color: var(--dark-gray-text-color);
  border-color: transparent;
}

.headerLinkInactive:hover {
  color: var(--cement);
  border-bottom: 0.1rem solid var(--darker-gray-hover-text-color);
}

.headerLinkMobileActive {
  display: block;
  background-color: var(--secondary-accent-color);
  color: var(--main-text-color);
  padding: 0.5rem 1rem 0.5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.headerLinkMobileInactive {
  display: block;
  color: var(--dark-gray-text-color);
  padding: 0.5rem 1rem 0.5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}