/* ------------------------------------------------------------- */
/* This portion handles the backgound image and title at the top */
/* ------------------------------------------------------------- */

.cures-bg-img-wrapper {
  background-color: var(--fourth-accent-color);
  background: url("../../assets/ilya-bronskiy-ozyzkHMhdx8-unsplash.jpg");
  background-size: 100rem;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  padding: 1rem 10rem;
  height: 30rem;
  max-height: 40rem;
  display: flex;
}

.cures-bg-img-wrapper h1 {
  background: linear-gradient(
    to bottom,
    var(--max-white-opacity-color) 0%,
    var(--rgba-black-color) 100%
  );
  padding: 3rem;
  font-size: 3rem;
  border-radius: 0.375rem;
}

/* --------------------------------------------------- */
/* This portion handles the middle section of the page */
/* --------------------------------------------------- */

.cures-overview-wrapper {
  background-color: var(--fourth-accent-color);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 2rem;
  margin: 0 auto;
}

.cures-overview-wrapper h1 {
  color: var(--white-text-color);
  margin: 0rem;
  font-size: 2rem;
}

.cures-overview-wrapper p {
  color: var(--medium-highlight-color);
  font-size: 1.2rem;
}

.cures-overview-icon {
  width: 4rem;
  height: 4rem;
  color: var(--medium-highlight-color);
  padding-right: 2rem;
}

.cures-overview-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 28%;
}

.curesOverviewHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 2rem;
}

/* ---------------------------------------------------------------------- */
/* This portion handles all the flippable cards at the bottom of the page */
/* ---------------------------------------------------------------------- */

.cures-card {
  margin: 2rem;
  min-width: 23rem;
  min-height: 21rem;
  position: relative;
  transform-style: preserve-3d;
  transition: all 0.5s ease-in-out;
  background-color: var(--secondary-accent-color);
  border: 0.01rem solid var(--white-accent-color);
  border-radius: 0.375rem;
}

.cures-card h2 {
  font-size: 1.7rem;
  margin-top: 1rem;
}

.cures-card:hover {
  cursor: pointer;
}

.cures-card-flipped {
  transform: rotateY(180deg);
}

/* this is for the front of the card */
.cures-card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.cures-card-front h2 {
  padding: 0;
  width: 80%;
  text-align: center;
  margin: 0;
}

.cures-card-front span {
  font-style: italic;
  padding-top: 1rem;
}

.cures-card-front-img {
  margin: 0;
  color: var(--main-text-color);
  height: 6rem;
  background-color: var(--white-text-color);
  border: 0.01rem solid var(--white-accent-color);
  border-radius: 0.375rem;
  padding: 1rem;
}

/* this is for the back of the card */
.cures-card-back {
  position: absolute;
  width: 100%;
  max-height: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transform: rotateY(180deg);
}

.cures-card-back h2 {
  margin: 0;
  text-align: center;
  width: 80%;
}

.cures-card-back p {
  margin: 0 1.3rem;
  font-size: 1rem;
  text-align: center;
}

/* this is for the title at the top of the flippable cards section */
.keyPoints {
  margin: 3rem auto;
}

/* this is for the row of flippable cards */
.cures-poi-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100rem;
  margin: 3rem auto;
}

/* -------------------------- */
/*        Media queries       */
/* -------------------------- */

@media (min-width: 1600px) {
  .cures-bg-img-wrapper {
    background-position: top;
    background-size: contain;
  }
}

@media (min-width: 768px) {
  .cures-bg-img-wrapper {
    justify-content: flex-end;
  }
}
@media (max-width: 768px) {
  .cures-bg-img-wrapper h1 {
    font-size: 4rem;
  }

  .cures-bg-img-wrapper {
    background-size: 100rem;
  }

  .cures-overview-wrapper {
    flex-direction: column;
  }

  .cures-overview-wrapper p {
    font-size: 1.2rem;
  }

  .cures-overview-section {
    flex-direction: column;
    padding-bottom: 2rem;
    width: fit-content;
  }
}

@media (max-width: 480px) {
  .cures-bg-img-wrapper {
    align-items: flex-start;
    justify-content: start;
  }

  .cures-bg-img-wrapper h1 {
    font-size: 2rem;
  }

  .cures-poi h1 {
    font-size: 2rem;
  }
}

@media (max-width: 360px) {
  .cures-bg-img-wrapper h1 {
    font-size: 1.5rem;
    padding: 0.5rem;
  }
}
