form {
  margin: 6rem 2rem 2rem 2rem;
  text-align: center;
}

.hyperlink {
  color: var(--third-accent-color);
  text-decoration: underline;
  cursor: pointer;
}

.backButton {
  margin: 5rem 0rem 0rem 1rem;
}
button a {
  color: whitesmoke;
}

.comingSoon {
  background: var(--dark-shadow);
  opacity: 0.3;
}

.insuranceCompaniesAdded {
  margin-bottom: 5rem;
}

.input-width-25 {
  max-width: 25%;
}

.insurance-suggest-form {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.insurance-suggest-form label {
  margin-top: 1rem;
}

.sandboxText {
  font-size: 1rem;
  color: var(--main-accent-color);
}

.pink-accent {
  color: var(--main-accent-color);
}

/* Controls the text in each modal */
.insuranceModalBody div p {
  display: flex;
  flex-direction: column;
}

/* Styles the company buttons section in modal */
.insuranceCompanies {
  display: flex;
  justify-self: center;
  flex-wrap: wrap;
  justify-content: center;
}

/* Styles the imgs in the buttons */
.insuranceButtonImg {
  width: 10rem;
  margin-top: 1rem;
}

@media (max-width: 600px) {
  .insuranceModalBody {
    flex: inline-block;
  }
}

@media (max-width: 1280px) {
  .pink-accent {
    color: var(--main-accent-color) !important;
  }
}

@media (max-width: 900px) {
  form {
    margin: 2rem 1rem 1rem 1rem;
  }
  .input-width-25 {
    max-width: 75%;
  }
}

@media (max-width: 640px) {
  .insuranceButtonImg {
    width: 8rem;
  }
}
