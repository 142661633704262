.planDetailsJumbotron {
  background: radial-gradient(
    circle,
    var(--second-accent-color-opacity) 0%,
    var(--second-accent-color) 100%
  );
  min-width: 100%;
  margin: 0rem;
  padding: 6rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.planDetailsJumbotron h2 {
  margin: 0rem 0rem 1.5rem;
  color: white;
  font-size: 2rem;
}

.planDetailsJumbotron p {
  margin: 0.4rem 0rem;
  font-size: 1.2rem;
}

.planDetailsBody {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--medium-highlight-color);
  width: 100%;
  min-height: 60vh;
}

.planDetailsCard {
  background-color: white;
  min-width: 75%;
  min-height: 40vh;
  border-radius: 0.375rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.planDetailsInput,
.planDetailsCostSection,
.planDetailsTableWrapper,
.planDetailsTotalWrapper {
  min-width: 97%;
}

.planDetailsInput .inputWrapper {
  margin: 0.7rem 0rem;
}

.planDetailsInput .inputWrapper {
  position: relative;
  display: inline-block;
  min-width: 100%;
}

.planDetailsInput .searchIcon {
  position: absolute;
  top: 50%;
  left: 0.625rem;
  transform: translateY(-50%);
  font-size: 1.2rem;
  color: var(--gray-text-color);
}

.planDetailsInput input {
  min-width: calc(100% - 5rem);
  min-height: 2rem;
  padding: 0.4rem 2.5rem 0.4rem 2.5rem;
  border: 0.08rem solid var(--gray-highlight-color);
  border-radius: 0.375rem;
  background-color: var(--medium-highlight-color);
}

.planDetailsInput input::placeholder {
  color: var(--gray-text-color);
  font-size: 1rem;
}

.planDetailsInput hr,
.planDetailsTableWrapper hr {
  margin: 1.5rem 0rem 1rem 0rem;
  min-width: 100%;
  border: 0.08rem solid var(--gray-highlight-color);
}

.planDetailsInput .responseWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0rem;
}

.planDetailsInput .responseWrapper .universalTableDivClass {
  min-width: 98%;
}

.planDetailsCostSection p {
  font-size: 1rem;
  margin: 0.5rem 0rem;
}

.planDetailsCostSection .italicGrey {
  color: var(--dark-gray-color);
  font-style: italic;
}

.planDetailsCostSection span,
.planDetailsTableWrapper span,
.planDetailsTotal span {
  font-weight: 700;
  font-style: normal;
}

.planDetailsTotalWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.planDetailsTotal {
  min-width: 20%;
}

.planDetailsTotalValues {
  min-width: 5%;
}

.planDetailsTotal p {
  font-size: 1rem;
  margin: 0.5rem 0rem;
  text-align: start;
}

@media (max-width: 1000px) {
  .planDetailsTable td {
    padding: 1.5rem 0;
  }
  .planDetailsTable td:nth-of-type(1)::before {
    content: "Expense:";
  }
  .planDetailsTable td:nth-of-type(2)::before {
    content: "Monthly:";
  }
  .planDetailsTable td:nth-of-type(3)::before {
    content: "Annual:";
  }
}
