.personalHealthSection {
  padding: 1rem;
}

.value {
  background: linear-gradient(var(--fourth-accent-color-opacity), var(--fourth-accent-color)),
    url("../../assets/sunrise.jpg");
  padding: 2rem;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.value h1 {
  text-align: left;
  max-width: 90rem;
  color: var(--white-text-color);
  margin: 2rem auto;
}

.valuesContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 2rem auto;
  background-color: var(--light-gray-opacity-color);
  border-radius: 0.375rem;
  padding: 1rem;
  max-width: 85rem;
}

.valuesContainer h2 {
  font-size: 1.5rem;
}

.valuesContainer p {
  font-size: 1.1rem;
}

.valueCard {
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 0rem 2rem;
}

.insurancePartners {
  background-color: var(--medium-highlight-color);
  padding-top: 0.5rem;
  border-bottom: 0.01rem solid var(--medium-gray-color);
}

.insurancePartnerContainer p {
  color: var(--main-text-color);
  text-align: center;
}

.insurancePartnerImageContainer img {
  border-radius: 0.375rem;
  padding: 0 0.5rem;
}

.insurancePartnerImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 1rem;
  align-items: center;
  max-width: 110rem;
  margin: 0 auto;
  flex-wrap: wrap;
}
.about {
  background-color: var(--fourth-accent-color);
  background: linear-gradient(var(--fourth-accent-color-opacity), var(--medium-highlight-color));
  padding-top: 8rem;
}

.about .card-image {
  margin: 0;
}

.about .card-left {
  padding: 0;
}

.about h1 {
  margin: 0;
  padding-top: 7rem;
  color: var(--white-text-color);
}

.horizontal-image-text {
  color: var(--main-text-color);
  padding: 2.2rem;
  border-radius: 0.375rem;
}

/* CAROUSEL STYLES AND POSITIONING */
/* XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */

.carousel {
  overflow: hidden;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  white-space: normal;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.indicators > Button.active {
  background-color: var(--fourth-accent-color-opacity);
}

@media (max-width: 600px) {
  .valuesContainer {
    flex-direction: column;
  }
  .valueCard {
    width: 80%;
  }
}
