/* Intermediary Page Styling */
/* XXXXXXXXXXXXXXXXXXXXXXXXX */
.interm-page {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    background-color: var(--medium-highlight-color);
  }
  
  .interm-page-logo {
    width: 20rem;
    margin-bottom: 3rem;
  }
  
  .interm-page-heading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .interm-page-heading h1 {
    margin: 0.8rem 1.5rem 0.8rem 0rem;
  }
  
  .interm-page p {
    margin-top: 0.8rem;
  }
  
  .spinnerContainer {
    position: relative;
    display: inline-block;
    align-items: center;
  }
  
  .spinner {
    width: 3.5rem;
    height: 3.5rem;
    border: 1rem solid #d3d3d3;
    border-top-color: var(--main-text-color);
    border-radius: 50%;
    animation: spinning 1s ease infinite;
    margin: 0.8rem 0rem 0.8rem 0rem;
  }
  
  @keyframes spinning {
    from {
      transform: rotate(0turn);
    }
    to {
      transform: rotate(1turn);
    }
  }