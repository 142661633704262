.outline-box {
  border-color: var(--main-text-color);
  border-style: solid;
  margin: 2rem 12rem 2rem 16rem;
  text-align: center;
  border-radius: 0.375rem;
  box-shadow: 0 0.125rem 0.32rem 0 var(--dark-shadow);
  min-width: 60vw;
}

.deleteHeader {
  margin-top: 0rem !important;
}

.grey-card {
  background-color: var(--medium-highlight-color);
  box-shadow: 0 0.125rem 0.32rem 0 var(--dark-shadow);
  margin: 2rem 12rem 2rem 16rem;
  border-radius: 0.375rem;
  min-width: 60vw;
}

.lightCard {
  background-color: var(--white-text-color);
}

.chartCard {
  background-color: var(--main-text-color);
  box-shadow: 0 0.125rem 0.32rem 0 var(--dark-shadow);
  margin: 2rem 1rem 2rem 7rem;
  border-radius: 0.375rem;
  min-width: 60vw;
  overflow: hidden;
}

.chartCardSubHeader {
  background-color: var(--white-accent-color);
  padding: 1rem;
}

.chartCardSubHeader p {
  margin: 0;
  text-align: center;
  padding: 0rem 1rem;
  font-size: 1.1rem;
}

.rxLinkSavings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem 0rem;
}

.rxLinkSavings img {
  height: 1.2rem;
}

.rxLinkSavings p {
  text-align: center;
}

.form label {
  padding-left: 1rem;
  color: #333;
}

.form :where(.inputBox input) {
  position: relative;
  height: 2.5rem;
  width: 90%;
  outline: none;
  font-size: 1rem;
  color: #707070;
  margin-top: 0.5rem;
  border: 0.063rem solid #ddd;
  border-radius: 0.375rem;
  padding: 0 1rem;
}

.chartCard h3 {
  color: var(--white-text-color);
  padding: 1rem;
  margin: 0rem;
}

.green-card {
  background-color: var(--fourth-accent-color);
  color: var(--secondary-accent-color);
  box-shadow: 0 0.125rem 0.32rem 0 var(--dark-shadow);
  margin: 5% 15% 1% 17%;
  border-radius: 0.375rem;
}

.green-card h3 {
  padding: 1rem;
  margin: 0rem;
}

.grey-card h3 {
  text-align: center;
}

.grey-card ul {
  list-style-type: circle;
}

td {
  /* Behave  like a "row" */
  border: none;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}

.universalTableDark tbody tr {
  background-color: var(--white-text-color) !important;
  color: var(--main-text-color);
}

.nestedTableBackground {
  background-color: var(--white-accent-color);
}

.nestedTable thead tr th {
  background-color: var(--main-text-color) !important;
  color: var(--white-text-color);
}

.universalTable {
  width: 100%;
  border-collapse: collapse;
}

.universalTable tr:not(:last-child) {
  border-bottom: 0.1rem solid var(--white-accent-color);
}

.universalTable tr {
  padding-bottom: 1.5rem;
}

.universalTable span {
  font-weight: 400;
  font-style: italic;
}

.universalTable tbody {
  background-color: var(--white-text-color);
  color: var(--main-text-color);
  border-radius: 0 0 0.375rem 0.375rem;
}

.universalTable th {
  text-align: left;
  padding: 1rem 1rem;
  font-weight: 500;
  color: black;
  font-size: 1.1rem;
  background-color: var(--white-accent-color);
}

.universalTable tr:nth-child(even) {
  background-color: var(--gray-color);
}

.planRankMobile tr,
.planRankMobileDashboard tr {
  background-color: var(--white-text-color) !important;
}

.planRankMobile tr:hover,
.planRankMobileDashboard tr:hover {
  background-color: var(--medium-highlight-color) !important;
  cursor: pointer;
}

.planRankMobile tr:hover td:first-child,
.planRankMobileDashboard tr:hover td:first-child
 {
  text-decoration: underline;
}

.universalTable td {
  padding: 1rem 1rem;
  font-size: 1.1rem;
}

.rxHistoryMobile td,
.planRankMobile td:first-child,
.planRankMobileDashboard td:first-child
 {
  border-bottom: none;
  font-weight: 500;
}

.customChartTooltip {
  background-color: var(--white-text-color);
  border: 0.1rem solid var(--white-accent-color);
  padding: 0.5rem;
  border-radius: 0.375rem;
  font-weight: 500;
}

.customChartTooltip p {
  font-size: 1.1rem;
}

.copay {
  margin: 2rem 0rem 0rem 10rem;
  display: inline-flex;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}

/* Health History Styling */
/* XXXXXXXXXXXXXXXXXXXXXXXXX */

.close {
  font-style: normal;
  font-size: 1rem;
  cursor: pointer;
  color: var(--main-text-color);
  margin: 0;
  text-align: right;
}

.noData {
  text-align: center;
  padding-bottom: 1rem;
  color: var(--light-gray-color);
  padding: 1rem;
}

.open {
  cursor: pointer;
  color: var(--main-text-color);
  background-color: var(--white-text-color);
  padding: 0.01rem 0.5rem;
  border-radius: 0.2rem;
  font-weight: 600;
  white-space: nowrap;
}

.open:hover {
  text-decoration: underline;
}

a.switch-class {
  font-size: 1rem;
  font-style: italic;
  color: var(--third-accent-color);
  padding: 0 0.75rem;
  margin: 1.5rem;
  white-space: nowrap;
  text-align: center;

  transition-duration: 0.25s;
  cursor: pointer;
}

a.switch-class:hover {
  text-decoration: underline;
}

.disclaimer {
  font-style: italic;
  font-size: 1rem;
  padding: 1rem;
  text-align: center;
}

.clinicalTrialStatus {
  font-weight: 700 !important;
  font-style: normal !important;
}

.clinicalTrialTitle {
  font-weight: 600 !important;
  font-style: normal !important;
}

@media (max-width: 1700px) {
  .copay {
    margin: 1rem;
  }
}

@media (max-width: 1000px) {
  .outline-box,
  .grey-card,
  .green-card {
    margin: 2rem 6rem 2rem 8rem;
  }

  .rxHistoryMobile td:nth-child(3) {
    font-weight: 600;
  }

  .rxLinkSavings {
    flex-direction: column;
  }

  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
    padding: 0.25rem;
  }
  /* Hide table headers (but not display: none;, for accessibility) */

  .noDataHeaders tr th {
    position: initial !important;
  }

  tr th {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 0;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: 500;
  }

  .planRankMobile td,
  .healthHistoryMobile td {
    padding: 0rem 0rem 0rem 10.5rem;
    border-bottom: none;
  }

  .nestedTableBackground {
    padding: 0 !important;
  }

  .nestedTable thead tr {
    display: none;
  }

  .nestedTable tbody {
    border-radius: 0 !important;
  }

  .rxCouponsMobile td {
    padding: 0rem 0rem 1.5rem 13rem;
    border-bottom: none;
    word-wrap: break-word;
  }

  .proceduresSubtableMobile td:nth-child(1),
  .proceduresSubtableMobile td:nth-child(2),
  .proceduresSubtableMobile td:nth-child(3),
  .healthHistoryMobile td:nth-child(1),
  .healthHistoryMobile td:nth-child(2),
  .healthHistoryMobile td:nth-child(3),
  .healthHistoryMobile td:nth-child(4),
  .planRankMobile td:first-child,
  .planRankMobile td:nth-child(2),
  .planRankMobileDashboard td:first-child,
  .planRankMobileDashboard td:nth-child(2),
  .rxInteractionsMobile td:nth-child(3),
  .clinicalTrialsMobile td {
    padding: 1.5rem 0;
  }

  .universalTable td {
    border-bottom: none;
    font-weight: 400;
  }

  .rxHistoryMobile td,
  .rxInteractionsMobile td {
    padding: 0rem 0rem 2rem 11rem;
  }

  .currentPlanCardTableMobile td {
    padding: 0rem 0rem 0rem 11rem;
  }

  td:before svg {
    padding: 0rem 0rem 0rem 11rem;
  }
  /*
       Label the data
   You could also use a data-* attribute and content for this. That way "bloats" the HTML,
    this way means you need to keep HTML and CSS in sync.
       */
  .rxCouponsMobile td:nth-of-type(1)::before {
    content: "Drug Name:";
  }
  .rxCouponsMobile td:nth-of-type(2)::before {
    content: "Brand/Generic:";
  }
  .rxCouponsMobile td:nth-of-type(3)::before {
    content: "Estimated Retail Cost:";
  }
  .rxCouponsMobile td:nth-of-type(4)::before {
    content: "Lowest Price w/ Coupon:";
  }
  .rxCouponsMobile td:nth-of-type(5)::before {
    content: "Coupon:";
  }
  .nestedTableBackground::before {
    content: none !important;
  }
  .diagnosisSubtableMobile td:nth-of-type(1):before {
    content: "Diagnosis Code:" !important;
  }
  .diagnosisSubtableMobile td:nth-of-type(2):before {
    content: "Diagnosis Display:" !important;
  }
  .proceduresSubtableMobile td:nth-of-type(1):before {
    content: "Procedure Date:" !important;
  }
  .proceduresSubtableMobile td:nth-of-type(2):before {
    content: "Procedure Code:" !important;
  }
  .proceduresSubtableMobile td:nth-of-type(3):before {
    content: "Procedure Display:" !important;
  }
  .healthHistoryMobile td:nth-of-type(1):before {
    content: "Visit Details:";
  }
  .healthHistoryMobile td:nth-of-type(2):before {
    content: "Rendering Provider:";
  }
  .healthHistoryMobile td:nth-of-type(3):before {
    content: "Diagnosis:";
  }
  .healthHistoryMobile td:nth-of-type(4):before {
    content: "Procedure:";
  }
  .healthHistoryMobile td:nth-of-type(5):before {
    content: "Patient Paid:";
  }
  .planRankMobileDashboard td:nth-of-type(2)::before {
    content: "Total Cost:";
    font-weight: 500;
  }
  .planRankMobile td:nth-of-type(1):before,
  .planRankMobileDashboard td:nth-of-type(1):before
   {
    content: "Plan Name:";
    font-weight: 500;
  }

  .planRankMobile td:nth-of-type(2):before {
    content: "Carrier:";
    font-weight: 500;
  }

  .planRankMobile td:nth-of-type(3):before {
    content: "Premium:";
  }

  .planRankMobile td:nth-of-type(4):before {
    content: "Pharmacy:";
  }

  .planRankMobile td:nth-of-type(5):before {
    content: "Medical:";
  }

  .planRankMobile td:nth-of-type(6):before {
    content: "Total Estimated Cost:";
  }

  .rxHistoryMobile td:nth-of-type(1):before {
    content: "Drug Information:";
  }

  .rxHistoryMobile td:nth-of-type(2):before {
    content: "Cost Details:";
  }

  .rxHistoryMobile td:nth-of-type(3):before {
    content: "Price Comparison:";
  }
  .rxInteractionsMobile td:nth-of-type(1):before {
    content: "Drugs:"
  }
  .rxInteractionsMobile td:nth-of-type(2):before {
    content: "Severity:"
  }
  .rxInteractionsMobile td:nth-of-type(3):before {
    content: "Interaction Description:"
  }

  .clinicalTrialsMobile td:nth-of-type(1):before {
    content: "Title:"
  }
  .clinicalTrialsMobile td:nth-of-type(2):before {
    content: "Organization:"
  }
  .clinicalTrialsMobile td:nth-of-type(3):before {
    content: "Status:"
  }
  .clinicalTrialsMobile td:nth-of-type(4):before {
    content: "Conditions:"
  }
  .clinicalTrialsMobile td:nth-of-type(5):before {
    content: "Full Trial:"
  }

  .currentPlanCardTableMobile td:nth-of-type(1):before {
    content: "Health History Cost:"
  }
  .currentPlanCardTableMobile td:nth-of-type(2):before {
    content: "Rx Cost:"
  }
  .currentPlanCardTableMobile td:nth-of-type(3):before {
    content: "Total Cost:"
  }
}

@media (max-width: 800px) {
  .rxHistoryMobile td:first-child,
  .rxHistoryMobile td:nth-child(2),
  .rxHistoryMobile td:nth-child(3),
  .rxInteractionsMobile td:first-child,
  .rxInteractionsMobile td:nth-child(2),
  .rxInteractionsMobile td:nth-child(3) {
    padding: 1.5rem 0;
  }

  .rxHistoryMobile td {
    padding: 0rem 0rem 0rem 11rem;
    border-bottom: none;
  }
}

@media (max-width: 700px) {
  .rxCouponsMobile td {
    padding: 1.7rem 0 1rem 0;
  }
}

@media (max-width: 500px) {
  .grey-card,
  .darkCard,
  .chartCard,
  .green-card {
    margin-right: 0.5rem;
    margin-left: 3.5rem;
    min-width: 18.75rem;
  }

  .outline-box {
    margin-left: 3rem;
    min-width: 18.75rem;
  }

  .customChartTooltip {
    max-width: 10rem;
  }

  .customChartTooltip p {
    font-size: 0.8rem;
  }

  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
    padding: 0.25rem;
  }

  td {
    padding: 0rem 0rem 0rem 9rem;
  }

}

@media (max-width: 300px) {
  .outline-box,
  .grey-card,
  .darkCard,
  .green-card {
    margin-right: 8rem;
    min-width: 250px;
  }

}
