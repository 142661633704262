.modalOpen {
  overflow: hidden;
}

/* Custom styling for scrollbar using Tailwind CSS classes */
/* For webkit browsers */
.scrollbarThin::-webkit-scrollbar {
  width: 0.5rem; /* width of the scrollbar */
}

.scrollbarThin::-webkit-scrollbar-track {
  background: var(--opacity-black-color); /* color of the track */
}

.scrollbarThin::-webkit-scrollbar-thumb {
  background: var(--gray-text-color); /* color of the thumb */
  border-radius: 0.5rem; /* roundness of the thumb */
}

.scrollbarThin::-webkit-scrollbar-thumb:hover {
  background: var(--dark-gray-text-color); /* color of the thumb on hover */
}

