.modalbg {
  background: var(--more-opacity-black-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
  top: -6rem;
  left: -2rem;
}

.modalLogo {
  width: 4rem;
  padding-bottom: 0.5rem;
}

.modalContainer {
  background: var(--secondary-accent-color);
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  margin: 0;
  padding: 1.3rem;
  border-radius: 0.375rem;
  border: 0.07rem solid var(--max-opacity-black-color);
  box-shadow: 0 0 1.4rem 0.5rem var(--slight-opec-black);
  max-width: 23.75rem;
}

.modalTitle {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modalBody > div {
  display: flex;
  flex-direction: column;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
}

.inputStyle {
  height: 1.75rem;
  padding: 0 0 0 0.625rem;
  margin: 0.32rem 0 0.625rem 0;
  border: 0.07rem solid var(--slight-opec-black);
  border-radius: 0.375rem;
  flex: 1 0 auto;
  box-shadow: 0 0.125rem 0.32rem 0 var(--slight-opec-black);
}

.nameInputStyle {
  height: 1.75rem;
  padding: 0 0 0 0.625rem;
  margin: 0.32rem 0 0.625rem 0;
  border: 0.07rem solid var(--slight-opec-black);
  border-radius: 0.375rem;
  flex: 1 0 auto;
  max-width: 45%;
  box-shadow: 0 0.125rem 0.32rem 0 var(--slight-opec-black);
}

.errorText {
  font-size: 1rem;
  color: var(--black-text-color);
}

.password-input {
  display: flex;
  flex-direction: column;
}

.password-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.eye-icon {
  position: absolute;
  right: 0.5rem;
  cursor: pointer;
}

.forgotPassLink {
  margin: 0rem 0rem 1rem;
  cursor: pointer;
  color: var(--main-text-color);
  text-decoration: underline;
}

.resetPassButton {
  margin-top: 1.5rem;
}
