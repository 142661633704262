.homePage h1,
.homePage h2,
.homePage h3,
.homePage h4,
.homePage h5,
.homePage h6,
.homePage p {
  margin: 0;
}

/* JUMBOTRON STYLES */
/* XXXXXXXXXXXXXXXXXXXXXXXXXXXXX */

.partnerContainer {
  padding: 2rem 0;
  background-color: var(--white-opacity-color);
}

.partnersImageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem 2rem 0 2rem;
  flex-wrap: wrap;
}

.homeWidthContainer {
  padding-top: 4rem;
}

.homeHeader h1 {
  font-size: 3rem;
  text-align: left;
}

.homeHeaderTextContainer {
  max-width: 40rem;
}

.homeHeaderTextContainer p {
  color: var(--dark-gray-text-color);
}

.homeHeaderContent {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.homeHeaderImage {
  width: 30rem;
  margin: 6rem 0rem;
  border: none;
  box-shadow: none;
  border-radius: 0.375rem;
}

/* COMPARE STYLES */
/* XXXXXXXXXXXXXXXXXXXXXXXXXXXXX */

.compareSection {
  display: flex;
  max-width: 90rem;
  justify-content: space-around;
  padding: 4rem 0rem 4rem 1rem;
  margin: 0 auto;
  align-items: start;
}

.compareSubheader {
  font-size: 1.6rem;
  color: var(--third-accent-color);
  font-weight: 500;
  padding-bottom: 1rem;
}

.compareImage img {
  border-radius: 0.375rem;
  box-shadow: 0.125rem 0.125rem 0.25rem var(--opacity-black-2);
}

.compareImage {
  padding-top: 3rem;
}

.compareContent {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}

.compareContent h3 {
  line-height: 2.35rem;
}

.compareContent p {
  color: var(--dark-gray-text-color);
  font-size: 1.4rem;
  line-height: 1.75rem;
}

.compareContent hr {
  margin: 2rem 0;
  border-color: var(--white-text-color);
}

/* CURES ACT STYLES */
/* XXXXXXXXXXXXXXXXXXXXXXXXXXXXX */

.curesImage {
  border-radius: 0.375rem;
  margin-right: 2rem;
  height: 20rem;
  box-shadow: 0.125rem 0.125rem 0.25rem var(--opacity-black-2);
}

.curesContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3rem;
}

.curesTextContainer {
  padding-left: 3rem;
}

.curesContent a {
  color: var(--main-text-color);
}

.curesContent p {
  color: var(--dark-gray-text-color);
}

.curesContainer {
  padding: 2rem 0 6rem 0;
  margin: 0 auto;
  max-width: 90rem;
}

/* REUSABLE SECTION STYLES */
/* XXXXXXXXXXXXXXXXXXXXXXXXXXXXX */

.darkHomeSection {
  background: linear-gradient(
    45deg,
    var(--main-text-color) 0%,
    var(--second-accent-color) 50%,
    var(--main-text-color) 100%
  );
  min-width: 100%;
  margin: 0rem 0rem;
  padding: 6rem 0rem;
}

.homeTitleCenter {
  max-width: 60rem;
  margin: 0 auto;
  text-align: center;
}

.homeTitleLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 80rem;
  padding-left: 2rem;
}

.homeTitleLeft h2 {
  font-size: 3rem !important;
}

.darkHomeSection h2 {
  color: var(--white-text-color);
  margin: 0;
  font-weight: 700;
  font-size: 2.5rem;
}

.darkHomeSection h4 {
  color: var(--gray-text-color);
  margin: 0;
  font-weight: 400;
  font-size: 1.6rem;
}

.darkHomeSection h5 {
  color: var(--white-text-color);
  margin: 0;
  font-weight: 600;
  font-size: 1.6rem;
}

.darkHomeSection p {
  color: var(--gray-text-color);
  margin: 0;
  font-size: 1.4rem;
}

.homeDarkCardClear {
  padding: 2.5rem;
  text-align: left;
  width: 30%;
  margin-top: 2rem;
}

.homeDarkCardFrosted {
  padding: 2.5rem 1rem 2.5rem 1.75rem;
  text-align: left;
  width: 25%;
  min-height: 12rem;
  margin-top: 2rem;
  background-color: var(--rgba-max-opacity-color);
  border-radius: 0.375rem;
  border: 0.01rem solid var(--rgba-opacity-black-color);
  box-shadow: 0 0 0.01rem var(--opacity-black-2);
}

.homeDarkIcon {
  color: var(--gray-text-color);
  font-size: 1.4rem;
}

.homeDarkFlex {
  display: flex;
  justify-content: space-evenly;
  align-items: start;
  flex-direction: row;
  max-width: 90rem;
  margin: 0 auto;
}

/* REUSABLE BUTTON & LINK STYLES */
/* XXXXXXXXXXXXXXXXXXXXXXXXXXXXX */

.buttonsContainer {
  display: flex;
  align-items: center;
}

.arrowLink {
  display: flex;
  align-items: center;
  font-size: 1.75rem;
  color: var(--dark-gray-text-color);
}

.ctaButtonLight {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.ctaButtonDark {
  display: flex;
}

/* MEDIA QUERIES MIN-WIDTH */
/* XXXXXXXXXXXXXXXXXXXXXXXXXXXXX */

@media (min-width: 960px) {
  .homeWidthContainer {
    max-width: 95rem;
    margin: 0 auto;
  }
  .homeHeader {
    position: relative;
    z-index: 1;
    margin: 0 auto;
    background: linear-gradient(
      135deg,
      var(--main-text-color),
      var(--secondary-accent-color),
      var(--white-text-color)
    );
  }
  .homeHeaderTextContainer {
    padding: 10rem 2rem 0 2rem;
  }
  .absolute-container {
    position: absolute;
    right: 50%;
    height: 100%;
    width: 200%;
    transform-origin: top-right;
    transform: skewX(-30deg);
    background-color: var(--white-text-color);
    z-index: -10;
    background-clip: padding-box;
    margin-right: -6rem;
  }
}

@media (max-width: 600px) {
  .homeHeaderImage {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
}

/* MEDIA QUERIES MAX-WIDTH */
/* XXXXXXXXXXXXXXXXXXXXXXXXXXXXX */

@media (max-width: 1024px) {
  .homeTitleCenter {
    padding: 2rem;
    text-align: left;
  }
  .homeDarkFlex {
    flex-direction: column;
    align-items: center;
  }
  .darkHomeSection {
    padding: 3rem 0;
  }
  .curesContent {
    flex-direction: row-reverse;
    margin-bottom: 3rem;
  }
  .curesImage {
    display: none;
  }
  .curesContent a {
    margin: 0 auto;
  }
  .homeHeaderContent {
    flex-direction: column;
  }
  .homeHeaderTextContainer {
    padding: 5rem 2rem 2rem 2rem;
  }
  .homeHeaderImage {
    margin: 2rem 0;
  }
  .homeHeader h1,
  .darkHomeSection h2 {
    font-size: 2rem;
  }
  .homeTitleLeft h2 {
    font-size: 2rem !important;
  }
  .homeDarkCardClear {
    width: auto;
    margin-top: 0;
  }
  .compareSection {
    flex-direction: column;
    padding: 1rem;
    align-items: center;
  }
  .compareImage img {
    width: 100%;
    margin-bottom: 3rem;
  }
  .homeDarkCardFrosted {
    width: 80%;
    min-height: auto;
  }
  .curesTextContainer {
    padding: 0;
  }
}
