/* tailwind.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Additional custom styles go here */
:root {
    --main-text-color: rgb(17, 29, 66);
  --second-accent-color: rgba(17, 24, 39, 1);
  --dark-shadow: rgb(12, 16, 39);
  --second-accent-color-opacity: rgba(17, 24, 39, 0.87);
  --third-accent-color: rgb(23, 128, 119);
  --third-accent-color-highlight: rgb(23, 128, 119, 0.15);
  --fourth-accent-color: rgb(79, 116, 140);
  --fourth-accent-color-opacity: rgba(79, 116, 140, 0.5);
  --danger-color: rgb(240, 0, 0);
  --success-color: rgb(0, 140, 0);

  /* Black */
  --black-text-color: rgb(0, 0, 0);
  --opacity-black-color: rgba(0, 0, 0, 0.1);
  --more-opacity-black-color: rgb(0, 0, 0, 0.5);
  --max-opacity-black-color: rgb(0, 0, 0, 0.7);
  --rgba-black-color: rgba(255, 255, 255, 0);
  --rgba-opacity-color: rgba(250, 250, 250, 0.1);
  --rgba-max-opacity-color: rgba(255, 255, 255, 0.03);

  /* White */
  --medium-highlight-color: whitesmoke;
  --secondary-accent-color: aliceblue;
  --white-accent-color: rgb(220, 220, 220);
  --white-text-color: rgb(255, 255, 255);
  --white-opacity-color: rgba(255, 255, 255, 0.5);
  --max-white-opacity-color: rgba(255, 255, 255, 0.8);

  /* Gray */
  --dark-gray-color: rgba(34, 41, 55, 0.7);
  --gray-highlight-color: rgba(79, 116, 140, 0.2);
  --medium-gray-color: rgba(128, 128, 128, 0.466);
  --light-gray-color: rgb(133, 138, 147);
  --dark-gray-text-color: rgb(122, 122, 122);
  --medium-gray-text-color: rgb(144, 150, 160);
  --gray-text-color: rgb(190, 190, 190);
  --gray-color: rgb(248, 248, 248);
  --light-gray-opacity-color: rgba(245, 245, 245, 0.8);
}